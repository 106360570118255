import "./import-jquery";

// Bootstrap Stuff:
import * as Popper from "@popperjs/core"
import { Offcanvas, Popover } from 'bootstrap';
// import "./bs-toggle-color-mode";

import {formValidation, plugins} from "./lib/formvalidation_1_8_1/es6/";
import Bootstrap5 from "./lib/formvalidation_1_8_1/es6/plugins/Bootstrap5";
import * as Ladda from "ladda";

import selectpicker from './lib/bootstrap-select_v1.14.0-gamma1';

import ds81_initAutovalidatorForm from './lib/jq-autovalidator-form_import.js';

import './lib/jquery.pause.min';
import "./lib/jq-timed-message";

let BvInstance = false;
let selectedModel = false;
let selectedGroup = false;


window.bvCallback = function (BV) {
	BvInstance = BV;
	BV.configure( {
		events : {
			bvRender : function (data) {
				// JavaScript code that executes when the Bazaarvoice application renders.
				console.log('bvRender', data);
			},
			submissionClose : function (data) {
				// JavaScript code that executes when the Bazaarvoice application renders.
				console.log('submissionSubmitted', data);
			},
			submissionSubmitted : function (data) {
				// JavaScript code that executes when the Bazaarvoice application renders.
				console.log('submissionSubmitted', data);
			}
		}
	});
}

 $(function() {

	 $('.model-select-form').find('select.rs-select').each(function () {
		 console.log('INIT SELECT');
		 $(this).selectpicker({
			 styleBase: 'form-control form-control-lg',
			 style: '',
			 liveSearch: true,
			 maxOptions: 1,
			 liveSearchPlaceholder: 'Nach Modellnummer suchen oder auswählen …'
		 }).on('loaded.bs.select changed.bs.select', function () {
			 if($(this).selectpicker('val').length)
			 {
				 $(this).parent().addClass('floating');
			 }
			 else
			 {
				 $(this).parent().removeClass('floating');
			 }
		 });
	 })

	 // $('section.form-content[data-stage="2"]').addClass('d-none');
	 $('section.form-content[data-stage="1"]').find('select[name="modelselect"]').on('changed.bs.select', function (e) {
		let v = $(this).val();
		if(typeof v === "object" && v.length == 1)
		{
			let val = v[0];
			selectedModel = val;
			let selectedOption = $(this).find('option[value="' + v + '"]');
			selectedGroup = selectedOption.closest('optgroup').attr('label');
			console.log('selectedModel', selectedModel);
			console.log('selectedGroup', selectedGroup);

			$('form.participation-form input[name="selected_model"]').val(selectedModel);

			$('.btn-trigger-review').removeClass('disabled').prop('disabled', false);
		}
		else
		{
			selectedModel = false;
			selectedGroup = false;
			$('.btn-trigger-review').addClass('disabled').prop('disabled', true);
		}
	 });

	 $('form.autovalidator-form').each(function () {
		 ds81_init_autovalidator_form($(this), function () {
			 // callback on success
			 $('section.form-content[data-stage="2"]').addClass('d-none');
			 $('section.form-content[data-stage="3"]').removeClass('d-none');
			 $('.hide-on-finish').addClass('d-none');
			 scrollToTarget($('section.form-content[data-stage="3"]'));


		 }, function () {
			 // callback on error
		 }, function () {
			 // callback on validated
		 }, function ($form, fieldName) {
			 // callback on invalid
			 if(typeof fieldName === "undefined" || fieldName === undefined) {
			 	scrollToTarget($form.find('.fv-plugins-bootstrap5-row-invalid').first().parent());
			 }
		 });
	 });

	 $('.btn-trigger-review').on('click', function (e) {
		 e.preventDefault();
		 e.stopPropagation();
		 console.log("REVIEW TRIGGER selectedModel", selectedModel);
		 if(selectedModel && selectedModel!== false && BvInstance !== false)
		 // if(selectedModel && selectedModel!== false)
		 {
		 	BvInstance.ui('rr', 'submit_review',{productId : 'Samsung ' + selectedGroup + ' (' + selectedModel + ')'});
			 setTimeout(function () {
				 $('section.form-content[data-stage="1"]').addClass('d-none');
				 $('section.form-content[data-stage="2"]').removeClass('d-none');
				 scrollToTarget($('section.form-content[data-stage="2"]'));
			 }, 3000);
		 }
	 });

	 function scrollToTarget($item) {
		 var scrollDist = Math.floor($item.offset().top) - 5;
		 var $navBar = $('nav.top-navbar');
		 if($navBar.length > 0)
		 {
			 scrollDist -= $navBar.outerHeight(true);
		 }
		 var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .1));
		 $('html, body').animate({ scrollTop: scrollDist}, {duration: harmonizedScrollDuration, easing: 'swing'});
	 }


 });

